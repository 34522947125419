import React, { useState,useRef } from 'react';
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import Layout from "../components/layout"
import Button from "../components/styles/Button"

export default function MediaList({pageContext: { node,list },location}) {
    const [headerColor,setHeaderColor] = useState("bg-white text-black")

    let hotE = node.media_details.find(n => {
        return n.hot == true
    })
    const refList = useRef(null)
    const showMore = (e)=>{
        let listDom = refList.current.querySelectorAll(".hidden")
        const listCount = 6
        for(let i=0;i<listDom.length;i++){
            if(i<listCount){
                listDom[i].classList.remove("hidden")
            }
        }
        if(listDom.length<=listCount){
            e.target.classList.add("hidden")
        }
    }
    console.log(node.media_details);

    return (
        <Layout bgColor={'bg-white'} footer={true} headerColor={headerColor}>
            <GatsbySeo
            title={node.seo.metaTitle}
            description={node.seo.metaDescription}
            
            openGraph={{
                url: location.href,
                title: node.seo.metaTitle,
                description: node.seo.metaDescription,
                images: [
                  { url: node.seo.shareImage.url },
                ],
                site_name: 'sotto',
              }}
            />
            
            <div className="container mx-auto pt-[193px] pb-[140px] sm:pt-24 sm:pb-20">
                <div className="flex justify-between items-end pb-[88px] sm:flex-col sm:items-start sm:pb-10">
                    <h1 className="text-[40px] leading-snug sm:mb-8">媒体中心</h1>
                    <ul className="flex items-center text-gray flex-wrap sm:w-full sm:justify-center">
                    <li className="mr-[38px] sm:mr-5"><a className="hover:text-black" href="/media">全部</a></li>
                    {
                        list.map((n,i)=>{
                            return (
                                <li key={i} className="mr-[38px] sm:mr-5"><a className={`${n.url==node.url?'text-black':'hover:text-black'}`} href={n.url}>{n.title}</a></li>
                            )
                        })
                    }
                    <li><a className="hover:text-black" href="/download">资料下载</a></li>
                    </ul>
                </div>
                
                {
                    <div className="flex justify-between items-start pb-[100px] xl:flex-col-reverse">
                        <div className="mr-115px w-[380px] xl:mr-0 xl:w-full">
                            <p className="pb-2.5 text-sm">{hotE.date}</p>
                            <h2 onClick={()=>window.location.href=hotE.url} className="text-3xl leading-snug pb-5 cursor-pointer">{hotE.title}</h2>
                            <p className="text-lg pb-10">{hotE.description}</p>
                            <Button href={hotE.url} variant="black">阅读更多</Button>
                        </div>
                        <figure className="w-[945px] xl:w-full xl:mb-2.5 overflow-hidden"><img className="w-full bg-light transform transition-all duration-1000 ease-linear hover:scale-110" src={`https://admin.cnsotto.com${hotE.seo.shareImage.url}`} alt="banner"/></figure>
                    
                    </div>
                }
                
                <div className="grid grid-cols-3 gap-x-[45px] gap-y-[120px] xl:grid-cols-2 sm:grid-cols-1 sm:gap-y-20" ref={refList}>
                {
                        node.media_details.map((n,i)=>{
                            return (
                                <div key={i} className={`bg-white ${i<6?'':'hidden'}`}>
                                    <figure className="h-[330px] xl:h-auto overflow-hidden"><img className="h-full w-full object-cover bg-light transform transition-all duration-1000 ease-linear hover:scale-110" src={`https://admin.cnsotto.com${n.seo.shareImage.url}`} alt="banner"/></figure>
                                    <p className="py-2.5 text-sm">{n.date}</p>
                                    <h2 onClick={()=>window.location.href=n.url} className="text-3xl mb-5 leading-snug truncate-2-lines min-h-[82px] cursor-pointer">{n.title}</h2>
                                    <p className="truncate-5-lines mb-10 min-h-[120px] sm:min-h-0">{n.description}</p>
                                    <Button href={n.url} variant="black">阅读更多</Button>
                                </div>
                            )
                        })
                }
                    
                    
                </div>
                <div onClick={showMore} className={`w-[240px] h-10 rounded-full bg-[#ededed] hover:bg-black hover:text-white flex justify-center items-center mx-auto mt-[82px] hover:cursor-pointer ${node.media_details.length>6?'':'hidden'}`}>显示接下来6个</div>

            </div>
        </Layout>
    )
}

